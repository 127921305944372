import richText from "utilities/richText"
import CallToAction from "./CallToAction"
import Image from "./Image"
import getTelLink from "utilities/getTelLink"
import isRichTextValid from "utilities/isRichTextValid"

export default function MarketContactInfo({ content }) {
  if ((!content?.address || !isRichTextValid(content?.address)) && !content?.phone) return null

  return (
    <section className="overlap-header pb-8 lg:pb-0 lg:-mt-32 xl:-mt-28 lg:-mb-28 xl:-mb-24 bg-warm-white">
      <div className="mx-6">
        <div className="flex flex-col md:flex-row max-w-screen-xl 2xl:max-w-screen-2xl mx-auto gap-8">
          {content?.address && isRichTextValid(content.address) && (
            <div className="mx-auto rounded-4xl shadow-icon-card bg-white p-5 flex items-center gap-4 z-10 md:w-1/2 w-full">
              <Image
                unoptimized
                className="h-auto w-24 shrink-0"
                width="95"
                height="95"
                src="/assets/map-pin.png"
                alt="Decorative Map Pin Icon"
              />
              <div>
                <h2 className="uppercase tracking-wider mb-1 text-charcoal font-secondary text-m1 lg:text-m2 2xl:l1 font-bold">
                  Address
                </h2>
                <div className="text-charcoal">{richText(content?.address, ", ")}</div>
                <CallToAction className="mt-2" style="secondary-light" href={content?.address_link?.url}>
                  Get Directions
                </CallToAction>
              </div>
            </div>
          )}
          {content?.phone && (
            <div className="mx-auto rounded-4xl shadow-icon-card bg-white p-5 flex items-center gap-4 z-10 md:w-1/2 w-full">
              <Image
                unoptimized
                className="h-auto w-24 shrink-0"
                width="95"
                height="95"
                src="/assets/smartphone.png"
                alt="Decorative Smartphone Icon"
              />
              <div>
                <h2 className="uppercase tracking-wider mb-1 text-charcoal font-secondary text-m1 lg:text-m2 2xl:l1 font-bold">
                  Phone
                </h2>
                <CallToAction className="mt-2" style="secondary-light" href={getTelLink(content.phone)}>
                  {content.phone}
                </CallToAction>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
