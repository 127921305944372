import Script from "next/script"
import { getAllOfficesPaths, getGlobals, getOfficePage } from "storyblok/api"
import Meta from "components/Meta"
import PageHeaderFull from "components/PageHeaderFull"
import FormSection from "components/FormSection"
import MarketContactInfo from "components/MarketContactInfo"
import OfficeTeamGrid from "components/OfficeTeamGrid"
import DynamicComponent from "components/DynamicComponent"
import isRichTextValid from "utilities/isRichTextValid"

export default function content({ story: { page, localTeamMembers, hubTeamMembers } }) {
  if (!page) return
  const { content } = page
  const description = content?.content?.content[0]?.content[0]?.text ?? "2060 Office Location"

  const missingMarketContactInfo = (!content?.address || !isRichTextValid(content?.address)) && !content?.phone

  return (
    <>
      <Meta
        info={{
          title: `2060 Digital: ${content?.name}`,
          og_title: `2060 Digital: ${content?.name}`,
          twitter_title: `2060 Digital: ${content?.name}`,
          description: `${description}`,
          og_description: `${description}`,
          og_image: `${content?.photo?.filename}`,
          twitter_image: `${content?.photo?.filename}`,
        }}
      />
      <article>
        <PageHeaderFull
          heading={content.name}
          content={content.content}
          eyebrow="Your Local Experts"
          image={content.photo}
          slight_bottom_padding={!missingMarketContactInfo}
        />
        <MarketContactInfo content={content} />
        <OfficeTeamGrid
          teamMembers={localTeamMembers}
          secondaryTeamMembers={hubTeamMembers}
          heading={`Meet the ${content.name} Team`}
        />
        {content.blocks && content.blocks.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />)}
        <FormSection heading="Connect With Our" heading2={`${content.name} Team`} />
      </article>
      <Script type="application/ld+json" id="office-schema">
        {`{
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "url": "https://www.2060digital.com",
        "name" : "2060 Digital",
        "address": {
          "@type": "PostalAddress",
        "streetAddress": "${content?.address}"
          },
        "telephone": "${content?.phone}"
        }`}
      </Script>
    </>
  )
}

export async function getStaticProps({ params: { slug }, preview = null }) {
  const globals = await getGlobals()
  const content = await getOfficePage(`offices/${slug}`, preview)

  return {
    props: {
      story: content ?? null,
      globals: globals ?? null,
    },
  }
}

export async function getStaticPaths() {
  return {
    paths: await getAllOfficesPaths(),
    fallback: false,
  }
}
