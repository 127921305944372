import Image from "./Image"
import PatternedDiv from "./PatternedDiv"
import getTelLink from "utilities/getTelLink"
import CallToAction from "./CallToAction"

const TeamMemberCard = ({ content, fallbackPhoto }) => {
  return (
    <div className="flex flex-col w-full sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg mx-auto rounded-2xl relative shadow-icon-card bg-white z-10">
      <div className="relative" style={{ paddingBottom: "100%" }}>
        {" "}
        {/* Set aspect ratio to 1:1 */}
        <Image
          src={content.image.filename || fallbackPhoto}
          alt={content.image.alt}
          fill
          sizes="(max-width: 520px) 100vw, (max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1024px) 33vw, (max-width: 1240px) 25vw, 25vw"
          className="rounded-t-2xl object-cover"
        />
      </div>
      <div className="p-5 text-charcoal">
        <h3 className="uppercase tracking-wider mb-1 xl:mb-2 leading-none">{content.name}</h3>
        <span>{content.title}</span>

        {content.phone && (
          <>
            <div className="h-10"></div>
            <div className="absolute bottom-4 left-5">
              <CallToAction style="secondary-light" href={getTelLink(content.phone)}>
                {content.phone}
              </CallToAction>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default function OfficeTeamGrid({
  teamMembers,
  secondaryTeamMembers,
  heading,
  eyebrow,
  fallbackPhoto = "/assets/blog-item-placeholder.svg",
}) {
  return (
    <section className="bg-warm-white py-8 lg:pt-36">
      <div className="mx-6">
        <PatternedDiv eyebrow={eyebrow} heading={heading}>
          <div className="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {teamMembers?.map(
              ({ content }) =>
                content && <TeamMemberCard key={content._uid} content={content} fallbackPhoto={fallbackPhoto} />,
            )}

            {secondaryTeamMembers?.map(
              ({ content }) =>
                content && <TeamMemberCard key={content._uid} content={content} fallbackPhoto={fallbackPhoto} />,
            )}
          </div>
        </PatternedDiv>
      </div>
    </section>
  )
}
